<template>
  <v-container fill-height justify-center>
    <v-card width="300" class="pa-4" rounded="lg" elevation="0">
      <v-card-text>
        <div class="text-h6 text-center font-weight-bold mb-8">
          <v-icon>mdi-login-variant</v-icon>
          Sign in
        </div>
        <v-text-field
          v-model="mobile_number"
          label="Mobile Number"
          outlined
          dense
          :rules="[rules.required, rules.mobile]"
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          outlined
          dense
          :append-icon="showPWD ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPWD ? 'text' : 'password'"
          @click:append="showPWD = !showPWD"
        ></v-text-field>
        <v-btn
          depressed
          class="primary text-capitalize"
          block
          @click="login()"
          :loading="api.isLoading"
          >Login</v-btn
        >
      </v-card-text>
    </v-card>

    <v-snackbar v-model="api.isError" :timeout="timeout" text>
      {{ api.error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="teal" text v-bind="attrs" @click="api.isError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
    
<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    password: "",
    mobile_number: "",
    timeout: 2000,
    rules: {
      required: (value) => !!value || "Required.",
      mobile: (v) => v.length >= 9 || "Min 9 characters",
    },
    showPWD: false,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    login() {
      this.api.url =
        this.$api.servers.auth + "/v1/en/login";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        if (resp.role == "ADMIN") {
          this.$store.commit("updateAuth", resp);
          this.$router.push({
            name: "PageDashboardHome",
          });
        } else {
          this.api.isError = true;
          this.api.error = "Invalid role.";
        }
      };
      this.api.params = {
        mobile_number: this.mobile_number,
        password: this.password,
      };
      this.$api.fetch(this.api);
    },
  },
};
</script>